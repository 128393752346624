<mat-toolbar color="primary"
             [class.active]="toolbarActive">
  <button mat-icon-button
          class="logo"
          (click)="toggleSidenav()">
    <img src="assets/favicon/android-chrome-192x192.png" />
  </button>
  <h1><span class="light">improv</span><strong>database</strong></h1>

  <span class="spacer"></span>

  <div class="search-container"
       [class.focused]="searchFocused || searchControl.value"
       [class.has-results]="gameSearchResults.length || searchControl.value.toLowerCase() === 'literally anything'">
    <div class="search-input">
      <mat-icon>search</mat-icon>
      <input (focus)="searchFocused = true"
             (blur)="searchFocused = false"
             type="text"
             placeholder="Search the database"
             [formControl]="searchControl">
    </div>

    <div class="menu">
      <div class="menu-item"
           *ngIf="searchControl.value.toLowerCase() === 'literally anything'">
           <mat-icon>thumb_up_alt</mat-icon>
        <span>Ha ha, very funny.</span>
      </div>

      <label *ngIf="gameSearchResults.length">Games:</label>
      <div class="menu-item"
           *ngFor="let game of gameSearchResults"
           (click)="searchControl.reset()"
           [routerLink]="'/games/' + game.slug">
        <mat-icon>{{game.icon}}</mat-icon>
        <span [innerHTML]="game.name"></span>
      </div>
      <label *ngIf="tagSearchResults.length">Tags:</label>
      <div class="menu-item"
           *ngFor="let tag of tagSearchResults"
           (click)="selectTagSearchResult(tag)">
        <mat-icon>sell</mat-icon>
        <span [innerHTML]="tag.name"></span>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="main">
  <div class="sidenav"
       [class.open]="sidenavOpen">
    <mat-nav-list class="top">

      <a *ngIf="isLoggedIn"
         mat-list-item
         routerLink="home"
         routerLinkActive="active">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </a>

      <a mat-list-item
         routerLink="welcome"
         routerLinkActive="active">
        <mat-icon>pan_tool</mat-icon>
        <span>Welcome</span>
      </a>

      <a mat-list-item
         routerLink="news"
         routerLinkActive="active">
        <mat-icon>article</mat-icon>
        <span>News</span>
      </a>

      <a mat-list-item
         routerLink="games"
         routerLinkActive="active">
        <mat-icon>sports_kabaddi</mat-icon>
        <span>Games</span>
      </a>

      <a mat-list-item
         routerLink="games/random">
        <mat-icon>casino</mat-icon>
        <span>Random Game</span>
      </a>

      <a mat-list-item
         routerLink="about"
         routerLinkActive="active">
        <mat-icon>live_help</mat-icon>
        <span>About</span>
      </a>

    </mat-nav-list>

    <mat-divider></mat-divider>

    <mat-nav-list class="bottom">
      <a mat-list-item
         routerLink="you"
         routerLinkActive="active"
         *ngIf="isLoggedIn">
        <mat-icon>account_circle</mat-icon>
        <span>You</span>
      </a>

      <a mat-list-item
         *ngIf="!isLoggedIn"
         (click)="openLogin()">
        <mat-icon>login</mat-icon>
        <span>{{loginString}}</span>
      </a>

      <a mat-list-item
         *ngIf="isLoggedIn"
         (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>{{logoutString}}</span>
      </a>

    </mat-nav-list>
  </div>
  <div class="sidenav-block"
       [class.open]="sidenavOpen"></div>

  <div class="content">
    <div class="content-outlet">
      <router-outlet></router-outlet>
    </div>

    <img src="assets/favicon/android-chrome-512x512.png"
         class="corner-logo" />
  </div>

</div>
