<div class="screen-margin"></div>

<div class="screen-content">
  <div class="block">

    <h2>
      <div class="icon-wrapper">
        <img src="assets/favicon/android-chrome-192x192.png" />
      </div>
      the<span class="light">improv</span><strong>database</strong>
    </h2>

    <p>What if there was a place for Improv?</p>

    <p>What if we could instantly connect with other Improvisers on a local, regional, national, and
      even global scale? What if we could utilize the power of modern technology to stop having to
      deal with the headaches of practicing and performing Improv? What if we could have an easy to
      use set of tools to help us promote what we do? What if we could easily connect our talents
      and knowledge with others in order to actually start making money doing what we love?</p>

    <p>Welcome to the <span class="light">improv</span><strong>database</strong>, which could (at
      least some day) be the answer to all those questions. Let me
      know if you need anything.</p>

    <p>- Shauvon McGill, creator</p>

  </div>

  <div class="block">
    <button mat-raised-button
            color="primary"
            (click)="openLogin()">Sign up today!</button>
  </div>

  <div class="block">
    <h3>Features that exist <em>right now!</em></h3>

    <div class="three-columns">
      <div class="column">
        <mat-icon>sports_kabaddi</mat-icon>
        <h4>Over 200 games and activities</h4>
        <p>Find your new favorite Improv game right here, even if they might go by a different name.</p>
      </div>

      <div class="column">
        <mat-icon>sell</mat-icon>
        <h4>Sophisticated tags and categories</h4>
        <p>Looking for a game to help with character development? Want some ice-breaker warmups? You can easily find them here.</p>
      </div>

      <div class="column">
        <mat-icon>search</mat-icon>
        <h4>Super useful search</h4>
        <p>Type literally anything in that search box up there - see what happens! Go on, do it.</p>
      </div>

      <div class="column">
        <mat-icon>casino</mat-icon>
        <h4>Random games</h4>
        <p>Not sure what to play with your Improv group? Hit the random button and go on an adventure.</p>
      </div>
    </div>
  </div>

  <div class="block">
    <h3>Benefits of signing up:</h3>

    <div class="three-columns">
      <div class="column">
        <mat-icon>sticky_note_2</mat-icon>
        <h4>Notes</h4>
        <p>Save your thoughts about different games or categories, either for your own reference or share them with the world.</p>
      </div>

      <div class="column">
        <mat-icon>forum</mat-icon>
        <h4>Connect with others</h4>
        <p>Find out who said what about your favorite games. Soon you'll be able to reach out to people and be part of the community!</p>
      </div>

      <div class="column">
        <mat-icon>add</mat-icon>
        <h4>Contribute</h4>
        <p>Submit new games to the database, or add tags to existing ones to help grow our collective knowledge base.</p>
      </div>

      <div class="column">
        <mat-icon>how_to_vote</mat-icon>
        <h4>Democracy</h4>
        <p>Vote for your preferred names for games, and help establish which ones are the global standard.</p>
      </div>
    </div>
  </div>

  <div class="block">
    <button mat-raised-button
            color="primary"
            (click)="openLogin()">Sign up today!</button>
  </div>
</div>

<div class="screen-margin"></div>
